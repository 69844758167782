<template>
    <div 
        class="shrink-0 w-24 text-center pl-2"
        :class="{ 
            'text-green-700': relevancyLevel === 'high',
            'text-yellow-800': relevancyLevel === 'medium',
            'text-red-700': relevancyLevel === 'low'
        }"
    >
        <p class="text-2xl font-semibold block">{{ formattedRelevancy }}</p>
        <span 
            class="w-full block h-0.5" 
            :class="{ 
                'bg-green-700': relevancyLevel === 'high',
                'bg-yellow-800': relevancyLevel === 'medium',
                'bg-red-700': relevancyLevel === 'low'
            }"
        />
        <VLozenge
            v-if="props.relevancy === 1"
            :variant="lozengeVariant"
        >Most Relevant</VLozenge>
    </div>
</template>

<script setup lang="ts">
/**
 * Vue dependencies
 */
import { computed } from 'vue';

/**
 * Local dependencies
 */
import { SearchResult } from '../types/search';
import { LozengeProps } from '../../../../../resources/js/Ui/Lozenge/props';

/**
 * Props
 */
const props = defineProps<{
    relevancy: SearchResult['relevancy']
}>();

const relevancyLevel = computed<string>(() => {
    if (props.relevancy >= .8) {
        return 'high';
    } else if (props.relevancy < .8 && props.relevancy > .5) {
        return 'medium';
    } else {
        return 'low';
    }
});
const lozengeVariant = computed<LozengeProps['variant']>(() => {
    switch (relevancyLevel.value) {
    case 'high':
        return 'success';
    case 'medium':
        return 'warning';
    case 'low':
        return 'error';
    default:
        return 'info';
    }
});
const formattedRelevancy = computed<string>(() => {
    return props.relevancy == 1 ? '1' : parseFloat(props.relevancy.toString()).toFixed(2);
});
</script>
