/**
 * External dependencies
 */
import axios, { AxiosError } from 'axios';
import { route } from 'ziggy-js';
import get from 'lodash/get';

/**
 * Local dependencies
 */
import { ExtractedLaravelError } from '../types/error-handling';
import { GetSearchFiltersResponse, GetSearchIndexRequest, GetSearchIndexResponse } from '../types/search';
import { consoleDevLog } from '../../../../../resources/js/Helpers/console-utils';

/**
 * Extract Laravel Error Message
 * 
 * Extracts Laravel error message & status code information sent by the server from the Axios error response. If the
 * information is not present in the response it returns a generic error message to be consumed by front end scripts.
 * 
 * @param {*} $error The axios error to extract required information from.
 */
const extractLaravelErrorMessage = (error: AxiosError): ExtractedLaravelError => {
    const message = get(error, 'response.data.message', 'An unknown error occurred. If this problem persists, please contact Swytch Tools support.');
    const statusCode = get(error, 'response.status', 500);
    const errors = get(error, 'response.data.errors');
    let response: ExtractedLaravelError = {
        message,
        statusCode
    };

    if (errors) {
        response = {
            ...response,
            errors
        };
    }

    return response;
};

/**
 * Get Search Results
 * 
 * Promise helper that makes a request to the api endpoint to retrieve search results.
 * 
 * @param {object} queryParams An oject with query params that the endpoint supports.
 */
const getSearchResults = (queryParams?: GetSearchIndexRequest): Promise<GetSearchIndexResponse> => {
    return new Promise((resolve, reject) => {
        axios.get<GetSearchIndexResponse>(route('api.core.search.search', {...queryParams}))
            .then(response => {
                const extractedResponse = get(response, 'data');
                return resolve(extractedResponse);
            })
            .catch((error: AxiosError) => {
                consoleDevLog(JSON.stringify(error));
                return reject(extractLaravelErrorMessage(error));
            });
    });
};

/**
 * Get Search Filters
 * 
 * Promise helper that makes a request to the api endpoint to retrieve possible search filter values.
 */
const getSearchFilters = (): Promise<GetSearchFiltersResponse> => {
    return new Promise((resolve, reject) => {
        axios.get<GetSearchFiltersResponse>(route('api.core.search.filters'))
            .then(response => {
                const extractedResponse = get(response, 'data');
                return resolve(extractedResponse);
            })
            .catch((error: AxiosError) => {
                consoleDevLog(JSON.stringify(error));
                return reject(extractLaravelErrorMessage(error));
            });
    });
};

export {
    extractLaravelErrorMessage,
    getSearchResults,
    getSearchFilters
};
