<template>
    <div class="w-full overflow-x-auto overflow-y-hidden border-b border-t border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <SearchTab
                v-for="tab in tabs" 
                :key="tab.name" 
                :name="tab.name"
                :count="tab.count"
                :current="currentlyViewing === tab.name"
                :changeTab="changeTab"
            />
        </nav>
    </div>
</template>

<script setup lang="ts">
/**
 * Vue dependencies
 */
import { computed } from 'vue';

/**
 * Local dependencies
 */
import { GetSearchIndexResponse, SearchTabProps } from '../../types/search';
import { arrayWithValues } from '../../../../../../resources/js/Helpers/array-utils';

/**
 * Components
 */
import SearchTab from './SearchTab.vue';

/**
 * Props
 */
const props = defineProps<{
    results: GetSearchIndexResponse
}>();
const currentlyViewing = defineModel('current');
const tabs = computed<SearchTabProps[]>(() => {
    if (!arrayWithValues(props.results)) {
        return [];
    }
    const totalResultCount = props.results.reduce((accumulator, result) => {
        return accumulator + result.count;
    }, 0);
    const formattedResults: SearchTabProps[] = [
        { name: 'All', count: totalResultCount > 0 ? totalResultCount : 0 }
    ]; 

    props.results.forEach(resultSet => {
        if (resultSet.count > 0) {
            formattedResults.push({
                name: resultSet.name,
                count: resultSet.count
            });
        }
    });

    return formattedResults;
});

/**
 * Functions
 */
const changeTab = (name: string) => {
    currentlyViewing.value = name;
};
</script>
