<template>
    <form @submit.prevent="submit" novalidate>
        <label class="mb-2 text-sm font-medium text-gray-900 sr-only" for="search">Search</label>
        <div class="flex justify-start items-center">
            <input 
                id="search" 
                v-model="query"
                autofocus
                class="block w-full p-4 text-gray-900 border border-gray-300 border-r-none rounded-l-lg bg-white focus:border-blue-500 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
                placeholder="Search"
                required
                type="text"
                :disabled="loading"
            >
            <button 
                type="submit" 
                class="inline-flex py-4 px-3 bg-blue-500 rounded-r-lg items-center border border-blue-500 text-white font-semibold disabled:cursor-not-allowed"
                :disabled="loading"
            >
                <i class="fa-solid fa-spinner animate-spin" v-if="loading"/>
                <i class="fas fa-magnifying-glass" v-else/>
                <span class="ml-2">{{ loading ? 'searching' : 'Search' }}</span>
            </button>
        </div>
    </form>
</template>

<script lang="ts" setup>
/**
 * Local dependencies
 */
import { consoleDevLog } from '../../../../../resources/js/Helpers/console-utils';

/**
 * Props
 */
withDefaults(defineProps<{
    loading?: boolean;
    submit?: () => void;
}>(), {
    loading: false,
    submit: () => consoleDevLog('No submit handler passed to SearchBox component.')
});

const query = defineModel<string|null>('query');
</script>
