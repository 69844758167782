<template>
    <v-modal>
        <template #default="{ close }">
            <div class="m-4">
                <div class="mb-1">
                    <SearchBox 
                        v-model:query="query" 
                        :loading="loading" 
                        :submit="fetchSearchResults" 
                        @close="close"
                    />
                </div>

                <div v-if="arrayWithValues(availableFilters)" class="mb-4 flex justify-end items-start">
                    <div class="flex items-start">
                        <span class="ml-2" v-for="applied in appliedFilters" :key="applied">
                            <VLozenge>{{ retrieveLabel(applied) }}</VLozenge>
                        </span>
                    </div>

                    <Popover v-slot="{ open }">
                        <PopoverButton ref="refBtn" class="rounded-md leading-none text-xs bg-indigo-700 text-white ml-2">
                            <div class="px-1.5 py-[3px]">
                                <span class="sr-only">{{ open ? 'Open' : 'Close' }}</span>
                                <i class="fas fa-filter"></i>
                            </div>
                        </PopoverButton>
                        <PopoverPanel 
                            as="div" 
                            ref="refDiv" 
                            :style="floatingStyles"
                            class="absolute w-56 border border-gray-200 rounded-md shadow-md bg-white z-10"
                        >
                            <ul class="divide-y divide-gray-300">
                                <li class="text-sm p-4 flex justify-start items-center" v-for="filter in availableFilters" :key="filter.id">
                                    <input 
                                        type="checkbox" 
                                        :id="filter.id"
                                        :value="filter.id"
                                        v-model="appliedFilters"
                                    />
                                    <label :for="filter.id" class="pl-2">{{ filter.name }}</label>
                                </li>
                            </ul>
                        </PopoverPanel>        
                    </Popover>
                </div>
                
                <SearchResults
                    :results="results" 
                    :query="query"
                    :currentSearchQuery="currentSearchQuery"
                    v-model:currently-viewing="currentlyViewing"
                />
            </div>
        </template>
        <template #activator="{ show }">
            <v-icon-button type="button" @click="show">
                <v-icon fa-icon="magnifying-glass"></v-icon>
            </v-icon-button>
        </template>
    </v-modal>
</template>


<script lang="ts" setup>
/**
 * Vue dependencies
 */
import { 
    ref,
    onMounted,
    watch
} from 'vue';

/**
 * Local dependencies
 */
import { GetSearchFiltersResponse, GetSearchIndexRequest, GetSearchIndexResponse, SearchType } from '../types/search';
import { getSearchFilters, getSearchResults } from '../Helpers/request-utils';
import { ExtractedLaravelError } from '../types/error-handling';
import { consoleDevLog } from '../../../../../resources/js/Helpers/console-utils';

/**
 * External dependencies
 */
import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useFloating, autoUpdate } from '@floating-ui/vue';

/**
 * Components
 */
import SearchBox from './SearchBox.vue';
import SearchResults from './SearchResults.vue';
import { arrayWithValues } from '../../../../../resources/js/Helpers/array-utils';

/**
 * Props 
 */
const query = ref<string|null>(null);
const loading = ref<boolean>(false);
const results = ref<GetSearchIndexResponse>([]);
const currentSearchQuery = ref<string|undefined>(undefined);
const currentlyViewing = ref<string>('All');
const availableFilters = ref<GetSearchFiltersResponse>([]);
const appliedFilters = ref<GetSearchIndexRequest['includes']>([
    'bikes',
    'customer',
    'dashboard_service',
    'fulfilment_group',
    'skus'
]);
const refBtn = ref<HTMLButtonElement|null>(null);
const refDiv = ref<HTMLDivElement|null>(null);
const { floatingStyles } = useFloating(refBtn, refDiv, {
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
});

// Functions

/**
 * Fetch Search Results
 * 
 * Performs the request to the api endpoint to retrieve search results.
 */
const fetchSearchResults = () => {
    if (isString(query.value) && query.value !== '') {
        loading.value = true;

        let queryParams: GetSearchIndexRequest = {
            query: query.value,
            includes: appliedFilters.value
        };

        getSearchResults(queryParams)
            .then(response => { 
                results.value = response;
            })
            .catch((error: ExtractedLaravelError) => {
                consoleDevLog('Call to getSearchResults from whithin core Search failed.');
                consoleDevLog(error);
                results.value = [];
            })
            .finally(() => {
                loading.value = false;
                currentSearchQuery.value = query.value ?? undefined;
                currentlyViewing.value = 'All';
            });
    }
};

/**
 * Retrieve Label
 * 
 * Retrieves the nicely formatted name from the available filters array. Used for Lozenge display of 
 * applied filters.
 * 
 * @param {string} id The filter id SearchType string to retrieve the label for. 
 */
const retrieveLabel = (id: SearchType): string => {
    if (arrayWithValues(availableFilters.value)) {
        const match = availableFilters.value.find(filter => filter.id === id);

        return match ? match.name : 'Unknown';
    }

    return 'Unknown';
};


/**
 * Lifecycle Hooks
 */
onMounted(() => {
    getSearchFilters()
        .then(response => availableFilters.value = response)
        .catch((error: ExtractedLaravelError) => {
            consoleDevLog('Call to getSearchFilters from whithin core Search failed.');
            consoleDevLog(error);
        });
});

/**
 * Watchers
 */
watch(
    () => appliedFilters.value,
    (newValue, oldValue) => {
        if (!isEqual(newValue, oldValue)) {
            fetchSearchResults();
        }
    }
);
</script>
