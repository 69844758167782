// app.js
import {createApp} from 'vue';
import registerTailwindComponents from '../../../../resources/js/registerTailwindComponents';
import Search from './Components/Search.vue';

if (document.getElementById('navbar-search')) {

    const searchApp = createApp();
    searchApp.use(registerTailwindComponents)
        .component('search-app', Search);

    // searchApp.mixin(Sentry.createTracingMixins({ trackComponents: true }));
    // Sentry.attachErrorHandler(searchApp);

    searchApp.mount('#navbar-search');
}
