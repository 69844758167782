<template>
    <div v-if="arrayWithValues(currentResultSet)">
        <SearchTabbedNavigation
            :results="results"
            v-model:current="currentlyViewing"
        />

        <SearchResult
            v-for="(result, index) in currentResultSet"
            :key="index"
            :title="result.title"
            :description="result.description"
            :type="result.type"
            :relevancy="result.relevancy"
            :url="result.url"
        />
    </div>
    <div class="w-full p-8" v-else-if="isString(currentSearchQuery) && currentSearchQuery !== ''">
        <VStatusCard
            heading="No Results"
            :text="`No search results found for the query '${ currentSearchQuery }'. Please try a different search term.`" 
        />
    </div>
</template>

<script setup lang="ts">
/**
 * Vue dependencies
 */
import { computed } from 'vue';

/**
 * Local dependencies
 */
import { arrayWithValues } from '../../../../../resources/js/Helpers/array-utils';
import { 
    GetSearchIndexResponse, 
    SearchResult as SearchResultData 
} from '../types/search';

/**
 * External dependencies
 */
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import isString from 'lodash/isString';

/**
 * Components
 */
import SearchTabbedNavigation from './TabbedNavigation/SearchTabbedNavigation.vue';
import SearchResult from './SearchResult.vue';


/**
 * Props
 */
const props = defineProps<{
    results: GetSearchIndexResponse;
    query?: null|string;
    currentSearchQuery?: string;
}>();
const currentlyViewing = defineModel('currentlyViewing');
const currentResultSet = computed<SearchResultData[]>(() => {
    if(!arrayWithValues(props.results)) {
        return [];
    }

    const currentResults: SearchResultData[] = [];

    switch (currentlyViewing.value) {
    case 'All':
        props.results.forEach(resultSet => currentResults.push(...resultSet.results));
        break;
    default: {
        const filtered = props.results.filter(resultSet => resultSet.name === currentlyViewing.value);

        filtered.forEach(resultSet => currentResults.push(...resultSet.results));
    }
        break;
    }

    return reverse(sortBy(currentResults, [(result) => result.relevancy]));
});
</script>
